header {
	&.contact {
		background: url('../img/header_contact.jpg') center;
		background-size: cover;	
		
		#entrance {
			h1 {
				padding: 0px;
				margin: -30px 0px 20px 0px;
				
				@media screen and (max-width: 1024px) {		
					font-size: 42px;	
				}
				
				@media screen and (max-width: 768px) {		
					font-size: 28px;	
				}					
			}
		}
	}
	
	&.newsletter {
		background: url('../img/header_newsletter.jpg') center;
		background-size: cover;		
		height: 500px;
		
		@media screen and (max-width: 1300px) {		
			height: 400px;	
		}		
	}
}

.contactinfo {
	position: relative;
	background: url("../img/bg_line2.gif") #eaeaea center;
	padding: 150px 0px 100px 0px;		
		
	#map {
		width: 100%; 
		height: 700px;	
		
		@media screen and (max-width: 992px) {	
			width: -webkit-calc(100% - 40px);
			width: calc(100% - 40px);
			height: 400px;	
			left: 20px;	
		}
	}
		
	.image {
		padding: 0px;	
	}
		
	.content {
		padding: 0px;			
		
		.block {
			float: right;
			position: relative;
			width: -webkit-calc(100% - 150px);
			width: calc(100% - 150px);			
			background: #FFF;
			margin: -60px -60px 0px 0px;
			padding: 25px;
			z-index: 2;
						
			@media screen and (max-width: 1600px) {	
				width: -webkit-calc(100% - 50px);
				width: calc(100% - 50px);						
			}
			
			@media screen and (max-width: 1080px) {				
				margin-left: 0px;
				width: 100%;
			}
				
			@media screen and (max-width: 992px) {	
				float: none;
				width: -webkit-calc(100% - 40px);
				width: calc(100% - 40px);	
				margin: 0px;
				left: 20px;			
			}	
			
			@media screen and (max-width: 768px) {		
				padding: 15px;
			}				

			@media screen and (max-width: 480px) {	
				padding: 10px;
			}							
			
			&.right {	
				float: left;	
				margin: -60px 0px 0px -60px;		

				@media screen and (max-width: 992px) {	
					float: none;
					width: -webkit-calc(100% - 40px);
					width: calc(100% - 40px);	
					margin: 0px;
					left: 20px;			
				}				
			}
			
			.inner {
				padding: 70px;	
				border: 1px solid #e0e0e0;	
				
				@media screen and (max-width: 1280px) {					
					padding: 40px;
				}
				
				@media screen and (max-width: 480px) {	
					padding: 20px;
				}					
				
				h1 {
					font-size: 50px;
					
					@media screen and (max-width: 1280px) {										
						font-size: 38px;
						padding: 0px 0px 25px 0px;
						margin: 0px 0px 25px 0px;
					}
								
					@media screen and (max-width: 540px) {	
						font-size: 28px;
					}		

					@media screen and (max-width: 480px) {	
						padding: 0px 0px 15px 0px;
						margin: 0px 0px 30px 0px;
					}						
					
					&:after {
						@media screen and (max-width: 1280px) {										
							left: -20px;
						}	

						@media screen and (max-width: 480px) {	
							left: 0px;
						}							
					}
				}	
				
				p {
					padding-bottom: 30px;
				}
				
				h4 {
					text-transform: uppercase;
					font-size: 18px;
					font-weight: 900;
				}
				
				p {
					font-size: 18px;
				}
				
				a {
					color: #272626;
				}
				
				.button {
					&:hover {
						color: #FFF;
					}
				}
				
				i {
					float: left;
					width: 21px;
					height: 21px;
					margin: 3px 7px 0px 0px;
					
					&.phone {
						background: url('../img/icon-phone.png');
						background-size: 21px 21px;
					}
					
					&.email {
						background: url('../img/icon-email.png');
						background-size: 21px 21px;
					}

					&.facebook {
						width: 23px;
						height: 23px;					
						background: url('../img/icon-facebook.svg'); 
						background-size: 23px 23px;
					}					
				}

			}

			ul {
				list-style: none;
				padding: 0;

				li {
					font-size: 18px;
					font-weight: 300;
					letter-spacing: 2px;
					line-height: 32px;

					&:first-child {
						&:before {
							content: "";
							float: left;
							width: 23px;
							height: 23px;
							margin: 5px 10px 0 0;
							background: url("../img/icon-facebook.svg");
							background-size: auto;
							background-size: 23px 23px;
						}
					}

					&:last-child {
						&:before {
							content: "";
							float: left;
							width: 23px;
							height: 23px;
							margin: 5px 10px 0 0;
							background: url("../img/icon-instagram.svg");
							background-size: auto;
							background-size: 23px 23px;
						}
					}
				}
			}
		}
	}
}

.openinghours {
	width: 100%;
	background: url('../img/bg_line.gif') center;
	padding: 70px 0px;
	
	h1 {
		position: relative;
		font-size: 52px;
		color: #010101;
		
		@media screen and (max-width: 1100px) {	
			font-size: 38px;
		}
	}	
	
	.image {
		position: relative;
		padding: 30px;	
		
		img {
			max-width: 100%;
			height: auto;		
		}
	}

	.content {
		div {
			padding: 30px;	
		}
	}	
}		

.contactform {
	width: 100%;
	min-height: 1000px;
	background: url('../img/contact-bg.jpg') top center;
	background-size: cover;
	
	.block {
		float: left;
		width: 100%;
		min-height: 300px;
		background: #FFF;
		margin: 100px 0px;
		padding: 25px;
		
		@media screen and (max-width: 768px) {		
			padding: 15px;
		}			
		
		.inner {
			float: left;
			padding: 70px;	
			border: 1px solid #e0e0e0;	
			
			@media screen and (max-width: 768px) {		
				padding: 20px;
			}				
			
			h1 {
				font-size: 50px;
				
				@media screen and (max-width: 768px) {		
					font-size: 28px;
				}					
			}
			
			strong {
				letter-spacing: 2px;
				font-size: 18px;			
			}
			
			form {
				float: left;
				
				.input {
					float: left;
					position: relative;
					width: 100%;
					padding: 0px 0px 15px 0px;

					&.checkbox {
						padding-left: 200px;

						@media screen and (max-width: 768px) {
							padding-left: 0px;
						}

						input {
							float: left;
							position: relative;
							margin-left: 0px;
						}

						label {
							float: left;
							text-align: left;
							width: auto;
							margin-left: 15px;
							line-height: 24px;
						}
					}
					
					&:after {
						content: "";
						position: absolute;
						width: -webkit-calc(100% - 200px);						
						width: calc(100% - 200px);
						height: 56px;
						border: 1px solid #e0e0e0;
						top: 5px;
						left: 205px;
						z-index: -1;
					}			
					
					label {
						float: left;
						width: 200px;
						text-align: right;
						font-size: 18px;
						line-height: 50px;
						font-weight: normal;
						padding: 0px 30px 0px 0px;
									
						@media screen and (max-width: 768px) {		
							text-align: left;
						}							
					}
					
					.input-container {
						float: left;
						width: -webkit-calc(100% - 200px);
						width: calc(100% - 200px);						
						position: relative;
									
						@media screen and (max-width: 768px) {		
							width: 100%;
						}							
					
						input {
							float: left;
							position: relative;
							width: 100%;
							height: 56px;
							padding: 10px;
							background: #FFF;
							border: 3px solid #000;
							font-size: 18px;	
							z-index: 1;	
							-webkit-transition: all 0.3s ease;
							transition: all 0.3s ease;

							&.errorField {
								background: #ca9eaa;
								color: #FFF;
							}							
						}
						
						textarea {
							float: left;
							position: relative;
							width: 100%;
							height: 180px;
							padding: 10px;
							background: #FFF;
							border: 3px solid #000;
							font-size: 18px;	
							z-index: 1;	
							
							&.errorField {
								background: #ca9eaa;
								color: #FFF;
							}												
						}						
						
						&:after {
							content: "";
							position: absolute;
							width: 100%;
							height: 100%;
							border: 1px solid #000;
							top: 4px;
							left: 4px;
							z-index: 0;
						}
					}										
				}
				
				button {
					position: relative;
					display: inline-block;
					font-family: "brandon-grotesque" , serif;
					font-size: 24px;
					font-weight: 700;
					color: #272626;
					text-transform: uppercase;	
					text-decoration: none;
					letter-spacing: 2px;
					background: #FFF;
					border: 1px solid #272626;
					-webkit-border-radius: 0px;
					border-radius: 0px;
					padding: 25px 25px;
					margin-left: 200px;
					margin-top: 20px;
					-webkit-transition: all 0.3s ease;
					transition: all 0.3s ease;					
										
					@media screen and (max-width: 768px) {		
						margin-left: 0px;
					}	
								
					&:hover {
						background: #272626;
						color: #FFF;
					}					
					
					&:after {
						content: "";
						position: absolute;
						width: -webkit-calc(100% + 3px);
						width: calc(100% + 3px);
						height: -webkit-calc(100% + 3px);
						height: calc(100% + 3px);		
						left: 2px;
						top: 2px;
						border: 1px solid #272626;
					}	

					&.loading {
						&:before {
							content: "";
							position: absolute;
							width: 42px;
							height: 42px;	
							background: url('../img/loader.svg');
							background-size: 42px 42px;
							right: -70px;
						}
					}	
				}				
				
			}			
		}	
	}
}