header {
	&.menu {
		background: url('../img/header_menu.jpg') center;
		background-size: cover;	
		
		#entrance {
			h1 {
				padding: 0px;
				margin: -30px 0px 20px 0px;
				
				@media screen and (max-width: 1024px) {		
					font-size: 42px;	
				}
				
				@media screen and (max-width: 768px) {		
					font-size: 28px;	
				}				
			}
		}
	}
}

.menu {
	position: relative;
	background: url("../img/bg_line2.gif") #eaeaea center;
	padding: 100px 0px 100px 0px;	

	@media screen and (max-width: 992px) {		
		padding: 50px 0px;
	}		
		
	&.menu-intro {
		background: url("../img/bg_line2.gif") #eaeaea center;
		padding: 100px 0px 0px 0px;

		@media screen and (max-width: 640px) {	
			padding: 50px 0px 0px 0px;
		}
		
		.mobile-nav {
			display: none;
			position: relative;
			width: 280px;	
			left: 50%;
			margin-left: -140px;		

			label {
				font-size: 20px;
			}	
			
			@media screen and (max-width: 768px) {	
				display: block;	
			}		
		
			.custom-select-mobile {
				position: relative;
				width: 280px;
				height: 50px;
				border: 1px solid #272626;
				margin-bottom: 10px;
				background: url('../img/dd.svg');
				background-repeat: no-repeat;
				background-size: 16px 16px;
				background-position: 250px 20px;
				

				
				&:after {
					content: "";
					position: absolute;
					width: -webkit-calc(100% + 3px);
					width: calc(100% + 3px);
					height: -webkit-calc(100% + 3px);
					height: calc(100% + 3px);		
					left: 2px;
					top: 2px;
					border: 1px solid #272626;
				}		

				div {
					float: left;
					width: 100%;
					height: 100%;
					overflow: hidden;
					
					select {
						float: left;
						position: relative;
						width: -webkit-calc(100% + 20px);
						width: calc(100% + 20px);	
						height: 100%;
						background: none;
						padding: 10px;
						border: 0px;
						z-index: 5;
						font-size: 20px;
					}
				}	
			}		
		
		}
		
		.menutypes {
			list-style: none;
			padding: 0px;
			text-align: center;
			
			@media screen and (max-width: 768px) {	
				display: none;
			}
						
			li {
				display: inline-block;
				margin: 0px 25px 25px 25px;
				
				@media screen and (max-width: 820px) {	
					margin: 0px 15px 25px 15px;
				}				
				
				.btn {
					min-width: 200px;
					margin-bottom: 20px;
					font-size: 20px;
					
					@media screen and (max-width: 820px) {	
						min-width: 160px;	
					}
					
					@media screen and (max-width: 640px) {	
						font-size: 16px;
						min-width: 120px;	
					}					
				}
			}
		}
	}
	
	&:nth-child(even) {
		background: url("../img/bg_line2.gif") #e4e4e4 center;
		padding: 100px 0px 100px 0px;	

		@media screen and (max-width: 768px) {		
			padding: 50px 0px;
		}			
	}	
	
	.image {
		padding: 0px;	
	}
		
	.content {
		padding: 0px;
		
		@media screen and (max-width: 992px) {	
			padding-top: 200px;	
			background: #FFF;
			background-repeat: no-repeat;
			background-size: 100%;
		}
		
		@media screen and (max-width: 480px) {	
			padding-top: 160px;
		}				
		
		.block {
			float: right;
			position: relative;
			width: -webkit-calc(100% - 150px);
			width: calc(100% - 150px);			
			background: #FFF;
			margin: -60px 0px 0px 0px;
			padding: 25px;
			z-index: 2;
			-webkit-transition: all 1.5s ease;
			transition: all 1.5s ease;
			
			&.animate {
				margin: -60px -60px 0px 0px;
				
				@media screen and (max-width: 768px) {	
					margin: 0px;
				}
			}
						
			@media screen and (max-width: 1600px) {	
				width: -webkit-calc(100% - 50px);
				width: calc(100% - 50px);						
			}
			
			@media screen and (max-width: 1080px) {				
				margin-left: 0px;
				width: 100%;
					
				&.animate {
					margin-left: 0px;
				}				
			}
				
			@media screen and (max-width: 992px) {	
				float: none;
				width: -webkit-calc(100% - 40px);
				width: calc(100% - 40px);	
				margin: 0px;
				left: 20px;			
			}	

			@media screen and (max-width: 480px) {	
				padding: 10px;
			}							
			
			&.right {	
				float: left;	
				margin: -60px 0px 0px 0px;	

				&.animate {
					margin: -60px 0px 0px -60px;
				}				

				@media screen and (max-width: 992px) {	
					float: none;
					width: -webkit-calc(100% - 40px);
					width: calc(100% - 40px);	
					margin: 0px;
					left: 20px;		

					&.animate {
						margin: 0px;
					}						
				}				
			}
			
			.inner {
				padding: 70px;	
				border: 1px solid #e0e0e0;	
				
				@media screen and (max-width: 1280px) {					
					padding: 40px;
				}
				
				@media screen and (max-width: 480px) {	
					padding: 20px;
				}					
				
				h1 {
					font-size: 50px;
					
					@media screen and (max-width: 1280px) {										
						font-size: 38px;
						padding: 0px 0px 25px 0px;
						margin: 0px 0px 25px 0px;
					}
								
					@media screen and (max-width: 540px) {	
						font-size: 28px;
					}		

					@media screen and (max-width: 480px) {	
						padding: 0px 0px 15px 0px;
						margin: 0px 0px 30px 0px;
						font-size: 24px;
					}						
					
					&:after {
						@media screen and (max-width: 1280px) {										
							left: -20px;
						}	

						@media screen and (max-width: 480px) {	
							left: 0px;
						}							
					}
				}	
				
				p {
					padding-bottom: 30px;
				}
				
				h3 {
					text-transform: uppercase;
					font-size: 18px;
					font-weight: 900;
				}				
				
				h4 {
					text-transform: uppercase;
					font-size: 18px;
					font-weight: 900;
				}
				
				p {
					font-size: 18px;
				}
				
				ul {
					list-style: none;
					padding: 0px;
					
					li {
						background: url('../img/bullet.svg') no-repeat 0px 3px;
						background-size: 20px 20px;
						padding-left: 28px;
						font-size: 18px;
						letter-spacing: 2px;
					}
				}

			}

		}			
	}
}

.download-carts {
	img {
		max-width: 100%;
		height: auto;
	}
}