header {
	&.rooms {
		background: url('../img/header_ruimtes.jpg') center;
		background-size: cover;	
		
		#entrance {
			h1 {
				padding: 0px;
				margin: -30px 0px 20px 0px;
				
				@media screen and (max-width: 1024px) {		
					font-size: 42px;	
				}
				
				@media screen and (max-width: 768px) {		
					font-size: 28px;	
				}					
				
			}
		}
	}
}