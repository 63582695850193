header {
	&.team, &.sfeer {
		background: url('../img/header_team.jpg') center;
		background-size: cover;	

		#entrance {
			h1 {
				padding: 0px;
				margin: -30px 0px 20px 0px;
				
				@media screen and (max-width: 1024px) {		
					font-size: 42px;	
				}
				
				@media screen and (max-width: 768px) {		
					font-size: 28px;	
				}					
			}
		}
	}
	&.sfeer {
		background-image: url('../img/header_sfeer.jpg');
	}	
}

.atmosphere {
	width: 100%;
	height: 750px;
	background: url('../img/sfeer.jpg') center;
	background-size: cover;
	margin: 50px 0px;
	
	@media screen and (max-width: 1280px) {
		width: 100%;
		height: 430px;
		background: url('../img/sfeer-sm.jpg') center;
		background-size: cover;	
	}
}

.profiles {
	margin-top: 100px;
	margin-top: 50px;
	
	.profile {

		&._event {

			.tile {
				background: #eaeaea;

				.inner {
					background: #eaeaea;
					color: #010101;

					h2 {
						color: #010101;

						&:after {
							background: #010101;
						}
					}

					p {
						color: #010101;
					}
				}
			}
		}

		article {
			figure {
				img {
					margin-bottom: 0px;
				}
			}
		}
		
		&:nth-child(2) {
			margin-top: 80px;
			
			@media screen and (max-width: 992px) {
				margin-top: 0px;
			}
		}
		
		img {
			margin-bottom: 25px;
		}
		
		.tile {
			position: relative;
			background: #4a7672;
			padding: 15px;
			margin-bottom: 25px;			
			
			&:before {
				content: "";
				display: block;
				padding-top: 100%;			
				
				@media screen and (max-width: 768px) {	
					display: none;
				}
								
			}
			
			&.grey {
				background: #eaeaea;
				
				.inner {
					border-color: #d8d8d8;
					color: #272626;
					
					h2 {
						&:after {
							background: #272626;
						}						
					}
					 
					p {
						color: #272626;
					}				
				}
			}
			
			.inner {
				top: 15px;
				left: 15px;
				height: -webkit-calc(100% - 30px);
				height: calc(100% - 30px);	
				width: -webkit-calc(100% - 30px);
				width: calc(100% - 30px);				
				position: absolute;
				border: 1px solid #749693;
				padding: 40px;
				color: #FFF;
				text-align: left;
				
				@media screen and (max-width: 992px) {	
					padding: 20px;
				}				
				
				@media screen and (max-width: 768px) {	
					padding: 20px;
					position: relative;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
				}
				
				h2 {
					font-size: 38px;
					position: relative;
					text-transform: uppercase;
					padding: 0px 0px 30px 0px;	
					margin: 0px 0px 30px 0px;
												
					@media screen and (max-width: 992px) {	
						font-size: 28px;
						padding: 0px 0px 20px 0px;
						margin: 0px 0px 20px 0px;
					}					
					
					&:after {
						content: "";
						position: absolute;
						width: 100px;
						height: 2px;
						background: #FFF;
						bottom: 0px;
						left: -20px;
						
						@media screen and (max-width: 992px) {	
							left: -10px;
						}							
					}	
				}				
				
				p {
					color: #FFF;
					
					&:after {
						content: "";
						display: none;
					}
				}
				
				.special {
					display: inline-block;
					position: relative;
					
					&:after {
						content: "";
						position: absolute; 
						width: 8px;
						height: 6px;
						background: url('../img/apostrof-white.png');
						top: 4px;
						left: 0px;
					}
				}					
			}
		}
	}
}