@font-face {
	font-family: 'the_missus_handbold';
	src: url('fonts/the_missus_hand_strong-webfont.woff2') format('woff2'),
	url('fonts/the_missus_hand_strong-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'the_missus_handnormal';
	src: url('fonts/the_missus_hand_normal-webfont.woff2') format('woff2'),
	url('fonts/the_missus_hand_normal-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'HUN-din1451';
	src: url('fonts/HUN-din1451.eot?#iefix') format('embedded-opentype'),  url('fonts/HUN-din1451.otf')  format('opentype'),
	url('fonts/HUN-din1451.woff') format('woff'), url('fonts/HUN-din1451.ttf')  format('truetype'), url('fonts/HUN-din1451.svg#HUN-din1451') format('svg');
	font-weight: normal;
	font-style: normal;
}

* {
	outline: none !important;
} 

html, body {
	height: 100%;
}

body {
	padding: 0px;
	margin: 0px;
	font-family: 'the_missus_handnormal';
	color: #010101;
	
	&.sitekick {
		padding-top: 70px;
		
		#navbar {
			top: 70px;
		}
	}
}

h1, h2, h3, h4 {
	font-family: 'HUN-din1451';
	font-weight: normal;
	text-transform: lowercase;
}

h1 {
	position: relative;
	padding: 0px 0px 40px 0px;	
	margin: 0px 0px 40px 0px;
	
	&:after {
		content: "";
		position: absolute;
		width: 100px;
		height: 2px;
		background: #010101;
		bottom: 0px;
		left: -30px;
		
		@media screen and (max-width: 768px) {
			left: -10px;
		}
	}	
	
	@media screen and (max-width: 768px) {		
		padding: 0px 0px 20px 0px;	
		margin: 0px 0px 20px 0px;		
	}
}

.special {
	display: inline-block;
	position: relative;
	line-height: 28px;
	
	&:after { 
		content: "";
		position: absolute; 
		width: 8px;
		height: 6px;
		background: url('../img/apostrof.png');
		top: 4px;
		left: 0px;
	}
}

.alter {
	font-family: 'American Typewriter';
	font-weight: bold;
}

a {
	color: #272626;
	text-decoration: underline;
}

.button {
	position: relative;
	display: inline-block;
	font-family: 'HUN-din1451';
	font-weight: normal;
	text-transform: lowercase;
	font-size: 24px;
	color: #272626;
	text-decoration: none;
	letter-spacing: 2px;
	border: 1px solid #272626;
	-webkit-border-radius: 0px;
	border-radius: 0px;
	padding: 15px 25px;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	
	&:hover {
		background: #272626;
		color: #FFF;
		text-decoration: none;
	}
	
	&:focus {
		text-decoration: none;
		color: #272626; 
	}
	
	&.active {
		background: #272626;
		color: #FFF;	
	}
	
	@media screen and (max-width: 1300px) {	
		font-size: 20px;
	}
	
	&:after {
		content: "";
		position: absolute;
		width: -webkit-calc(100% + 3px);
		width: calc(100% + 3px);
		height: -webkit-calc(100% + 3px);
		height: calc(100% + 3px);		
		left: 2px;
		top: 2px;
		border: 1px solid #272626;
	}
	
	&.light {
		color: #FFF;
		border: 2px solid #FFF;
		
		&:after {
			border-color: #FFF;
			width: -webkit-calc(100% + 4px);
			width: calc(100% + 4px);
			height: -webkit-calc(100% + 4px);
			height: calc(100% + 4px);				
		}
	}
}

.contain-stamp {
	position: relative;
	
	&:after {
		content: "";
		position: absolute;		
		width: 194px;
		height: 211px;
		background: url('../img/stamp.png');
		z-index: 2;
		bottom: -100px;
		left: -100px;
		
		@media screen and (max-width: 992px) {	
			left: 0px;
			bottom: -150px;
		}				
		
		@media screen and (max-width: 768px) {	
			display: none;
		}		
	}
}

.contain-text-stamp {
	position: relative;
	
	&:after {
		content: "";
		position: absolute;		
		width: 194px;
		height: 211px;
		background: url('../img/stamp.png');
		z-index: 3;
		bottom: -150px;
		left: -150px;
		opacity: 0.7;
		
		@media screen and (max-width: 768px) {		
			display: none;
		}			
	}
}

#btn-reserve {
	position: fixed;
	top: 50%;
	left: 0px;
	height: 230px;
	width: 67px;
	background: url('../img/btn-reserve.png');
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 21px;
	z-index: 999;
	
	@media screen and (max-width: 768px) {
		display: none;
	}	
}

#navbar {
	position: fixed;
	width: 100%;
	top: 0px;
	z-index: 99;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;	
	
	.container {

		@media screen and (max-width: 1500px) {
			position: relative;
		}

		@media screen and (max-width: 1120px) {
			width: 100%;
			padding: 0px;
		}
	}
	
	.homepage {
		display: none;
		position: fixed;
		top: 22px;
		left: 20px;

		@media screen and (max-width: 1120px) {
			display: block;
		}				
		
		svg {
			fill: #FFF;
			width: 24px;
			height: 24px;
		}
	}
	
	nav {
		position: relative;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);

		ul {
			float: left;
			width: 100%;
			list-style: none;
			padding: 0px 0px 20px 0px;
			margin-top: 35px;
			border-bottom: 1px solid rgba(255,255,255, 0.5);
			
			@media screen and (max-width: 1120px) {
				position: absolute;
				visibility: hidden;
				opacity: 0;
				left: 0px;
				height: -webkit-calc(100vh - 70px);
				height: calc(100vh - 70px);
				overflow-y: auto;
				width: 100%;
				margin: 70px 0px 0px 0px;
				background: rgba(0,0,0,0.90);
				-webkit-transition: opacity 0.3s ease, visibility 0.3s ease;
				transition: opacity 0.3s ease, visibility 0.3s ease;
			}
			
			li {
				float: left;
				position: relative;
				padding: 0px 24px 0px 0px;
				
				@media screen and (max-width: 1400px) {
					padding: 0px 20px 0px 0px;
				}					
				
				@media screen and (max-width: 1300px) {
					padding: 0px 10px 0px 0px;
				}	

				@media screen and (max-width: 1120px) {
					float: left;
					clear: left;
					width: 100%;
					border-bottom: 1px solid rgba(255,255,255,0.2);
					padding: 5px 0px;					
				}

				&.item-english {
					display: none;
					
					@media screen and (max-width: 1120px) {
						display: block;
					}
				}	

				&:nth-last-child(2) {
					padding-right: 0px;
				}
				
				&.has-sub {
					background: url('../img/icon-nav-parent.svg') center right 15px no-repeat;
					background-size: 16px 16px;  
					
					@media screen and (max-width: 1300px) {
						background: url('../img/icon-nav-parent.svg') center right 7px no-repeat;
						background-size: 14px 14px;  					
					}
					
					@media screen and (max-width: 1120px) {
						background: url('../img/icon-nav-parent.svg') top 5px right 15px no-repeat;
						background-size: 16px 16px; 
					}					
					
					&.active {
						background: url('../img/icon-nav-parent-dark.svg') center right 15px no-repeat;
						background-size: 16px 16px;  	
						background-color: #FFF;
						
						@media screen and (max-width: 1300px) {
							background: url('../img/icon-nav-parent-dark.svg') center right 7px no-repeat;
							background-size: 14px 14px;  	
							background-color: #FFF;					
						}						
						
						@media screen and (max-width: 1120px) {
							background: url('../img/icon-nav-parent-dark.svg') top 5px right 15px no-repeat;
							background-size: 16px 16px; 
						}											
						
						a {
							background: none;
						}
						
						ul {
							li {
								a {
									&:hover {
										background: #272626;
										color: #FFF;
										font-weight: 700;
									}									
								}
								
								&.active {
									@media screen and (max-width: 1120px) {
										background: none;
									}									
								
									a {
										background: #272626;
										color: #FFF;
										font-weight: 700;		

										@media screen and (max-width: 1120px) {
											background: none;
										}											
									}
								}
							}
						}						
						
					}
	
				}

				&.li-switch {
					float: right;

					.btn-switch {
						float: right;
						border: 1px solid rgba(255,245,235, 0.25);
						-webkit-transition: all 0.3s ease;
						transition: all 0.3s ease;

						@media screen and (max-width: 1500px) {
							margin-right: 160px;
						}

						@media screen and (max-width: 1120px) {
							float: left;
							margin: 0;
							border: 0;
						}

						@media screen and (min-width: 1121px) {
							&:hover {
								border: 1px solid rgba(255, 255, 255, 1);
							}

							&:before {
								display: none;
							}

							&:after {
								display: none;
							}
						}
					}
				}

				&.active {
					a {
						background: #FFF;
						color: #272626;
						
						@media screen and (max-width: 1120px) {
							background: none;
							color: #FFF;
							text-decoration: underline;
						}	
					
					}
				}
				
				a {
					float: left;
					position: relative;
					font-family: 'HUN-din1451';
					text-transform: lowercase;
					font-weight: normal !important;
					font-size: 18px;
					letter-spacing: 1px;
					color: #FFF;
					text-decoration: none;
					padding: 12px 15px;
					
					@media screen and (max-width: 1300px) {
						padding: 10px 15px;
					}					

					@media screen and (max-width: 1120px) {
						color: #FFF !important;
					}	
					
					&:before {
						content: "";
						position: absolute;
						width: 0px;
						height: 1px;
						background: #FFF;
						top: 0px;
						left: 50%;
						-webkit-transform: translateX(-50%);
						transform: translateX(-50%);		
						-webkit-transition: width 0.3s ease;
						transition: width 0.3s ease;	
					}
					
					&:hover {
						&:before {
							width: 100%;
						}
					}						
						
					&:after {
						content: "";
						position: absolute;
						width: 0px;
						height: 1px;
						background: #FFF;
						bottom: 0px;
						left: 50%;
						-webkit-transform: translateX(-50%);
						transform: translateX(-50%);		
						-webkit-transition: width 0.3s ease;
						transition: width 0.3s ease;	
					}
					
					&:hover {
						&:after {
							width: 100%;
						}
					}					
					
				}
				
				ul {
					display: none;
					float: left;
					position: absolute;
					min-width: 200px;
					border: 0px;
					padding-top: 34px;
					margin: 34px 0px 0px 0px;
					z-index: 2;
					
					@media screen and (max-width: 1120px) {
						margin: 0px;
						margin-left: 20px;
					}	
						
					&:after {
						content: "";
						position: absolute;
						width: -webkit-calc(100% + 2px);
						width: calc(100% + 2px);
						height: -webkit-calc(100% - 53px);
						height: calc(100% - 53px);							
						border: 1px solid #FFF;	
						left: 3px;
						top: 38px;
						z-index: 0;
						
						@media screen and (max-width: 1120px) {
							display: none;
						}	
					}
					
					@media screen and (max-width: 1120px) {
						position: relative;
						padding: 0px;
						height: auto;
					}

					li {
						position: relative;
						float: left;
						clear: left;
						width: 100%;
						background: #FFF;
						padding: 0px !important;
						z-index: 1;
							
						@media screen and (max-width: 1120px) {
							background: none;
						}	
						
						a {
							float: left;
							width: 100%;
							padding: 12px 20px;
							color: #272626;
							font-weight: 700;
							
							@media screen and (max-width: 1120px) {
								color: #FFF;
							}
							
							&:hover {
								background: #272626;
								color: #FFF;
								font-weight: 700;
							}
						}
					}	
				}
			}
		}
		
		&.toggle {
		
			ul {
				visibility: visible;
				opacity: 1;
				
				li {					
					a {
						&:before {
							display: none;	
						}						
							
						&:after {
							display: none;
						}					
					}
				
					ul {
						display: none;
					}
					
					&.has-sub {
						
						&:hover {
							ul {
								display: block;
								margin: 0px;
								background: none;
								
								li {
									background: none;
									
									a {
										color: #FFF;
										margin-left: 15px;
									}
								}
							}
						}
						
						ul {
							li {
								&:hover {
									background: none;
								}
								
								a {
									&:hover {
										background: none;
									}								
								}
							}
						}
					} 

					&.active {
						
						ul {
							li {
								a {
									text-decoration: none;
								}
								
								&.active {
									a {
										text-decoration: underline;
									}
								}
							}
						}
					}	
					
				}
			}	
		}
				
	}
	
	@media screen and (max-width: 1120px) {	
		height: 70px;
		border-bottom: 1px solid rgba(255,255,255, 0.5);
		z-index: 9999;
		
		&.small {
			background: #FFF;
			-webkit-box-shadow: 0 5px 5px -5px rgba(0,0,0,0.3);
			box-shadow: 0 5px 5px -5px rgba(0,0,0,0.3);
			
			nav {
				ul {
					margin-top: 15px;
					padding-bottom: 5px;
					
					li {
						a {
							font-weight: 700 !important;
							
							&.active {
								background: #272626;
								color: #FFF;
								font-weight: 700;
							}
						}
					}
				}
			}
			
			.facebook {
				position: absolute;
				top: 15px;
				right: 75px;
				width: 42px;
				height: 42px;			
			
				svg {
					
					* {
						fill: #272626;
					}
				}	
			}

			.hamburger {
				
				svg {
					fill: #272626;
				}
				
			}

			.toggle {
				padding-top: 55px;
			}			
		}	

		.english {
			border: 0px !important;		
			top: 14px !important;
			right: 160px !important;
			
			i {
				width: 34px !important;
				height: 34px !important;
				background-size: 34px 34px !important;
			}
		}		
	}
	
	.english {
		position: absolute;
		top: 35px;
		right: 140px;
		width: 42px;
		height: 42px;

		@media screen and (max-width: 1500px) {
			right: 120px;
		}
		
		i {
			position: absolute;
			width: 32px;
			height: 32px;
			background: url('../img/flag_en.svg') no-repeat;
			background-size: 32px 32px;
			top: 50%;
			left: 50%;
			-webkit-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);
		}
	}

	.instagram {
		position: absolute;
		top: 35px;
		right: 85px;
		width: 42px;
		height: 42px;
		-webkit-border-radius: 50%;
		border-radius: 50%;
		border: 2px solid #FFF;

		@media screen and (max-width: 1500px) {
			right: 65px;
		}

		svg {
			position: absolute;
			width: 24px;
			height: 24px;
			top: 50%;
			left: 50%;
			-webkit-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);

			* {
				fill: #FFF;
			}
		}

		@media screen and (max-width: 1120px) {
			top: 15px;
			right: 115px;
			width: 38px;
			height: 38px;
			border: 0px;
		}

	}
	
	.facebook {	
		position: absolute;
		top: 35px;
		right: 35px;	
		width: 42px;
		height: 42px;
		-webkit-border-radius: 50%;
		border-radius: 50%;
		border: 2px solid #FFF;

		@media screen and (max-width: 1500px) {
			right: 15px;
		}
	
		svg {
			position: absolute;
			width: 24px;
			height: 24px;
			top: 50%;
			left: 50%;
			-webkit-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);			
						
			* {
				fill: #FFF;
			}
		}
		
		@media screen and (max-width: 1120px) {
			top: 15px;
			right: 75px;	
			width: 38px;
			height: 38px;
			border: 0px;
		}
		
	}	
	
	.hamburger {
		display: none;
		position: fixed;
		top: 15px;
		right: 20px;
		
		@media screen and (max-width: 1120px) {
			display: block;
		}
		
		svg {
			width: 40px;
			height: 40px;
			fill: #FFF;
		}
	}
	
	&.small {
		background: #FFF;
		-webkit-box-shadow: 0 5px 5px -5px rgba(0,0,0,0.3);
		box-shadow: 0 5px 5px -5px rgba(0,0,0,0.3);
		
		.homepage {
			svg {
				fill: #272626;
			}		
		}
		
		nav {
			ul {
				margin-top: 15px;
				padding-bottom: 5px;
				
				li {
					a {
						color: #272626;
						font-weight: 700;
						
						&:before {
							background: #272626;	
						}						
							
						&:after {
							background: #272626;
						}
					}
					
					&.active {
						a {
							background: #272626;
							color: #FFF;	

							@media screen and (max-width: 1120px) {							
								background: none;
							}
						}
					}
					
					&.has-sub {
						&.active {
							background-color: #272626;
							background: url("../img/icon-nav-parent.svg") center right 15px no-repeat;
							background-size: 16px 16px;			

							@media screen and (max-width: 1120px) {					
								background: url("../img/icon-nav-parent.svg") top 5px right 15px no-repeat;								
								background-size: 16px 16px; 
							}
						}
					}
				}
			}
		}
		
		.english {
			border: 0px solid #272626;		
			top: 18px;	
		}

		.instagram {
			border: 0px solid #272626;
			top: 18px;

			@media screen and (max-width: 1120px) {
				top: 16px;
			}

			svg {

				* {
					fill: #272626;
				}
			}
		}
		
		.facebook {
			border: 0px solid #272626;	
			top: 16px;
			
			@media screen and (max-width: 1120px) {
				top: 14px;
			}
			
			svg {
				
				* {
					fill: #272626;
				}
			}	
		}

		.hamburger {
			
			svg {
				fill: #272626;
			}
			
		}	
	}		
}

header {
	position: relative;
	width: 100%;
	height: -webkit-calc(100% - 50px);
	height: calc(100% - 50px);	
	z-index: 2;
	background-color: #000;

	@media screen and (max-width: 992px) {			
		height: 80%;
	}
	
	@media screen and (max-width: 768px) {			
		height: -webkit-calc(100% - 50px);
		height: calc(100% - 50px);	
	}	

	&.stroke {
		height: 103px;
		background: #272626;
			
		@media screen and (max-width: 768px) {			
			height: 70px;
		}		
		
		&:after {
			display: none;
		}
	}
	
	.background {
		position: absolute;
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: cover;	
		z-index: 0;
	}
	
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		max-width: 989px;
		height: 219px;
		bottom: 0px;
		left: 50%;
		background: url('../img/header_shadow.png');
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		z-index: 1;
	}
	
	&:after {
		content: "";
		position: absolute;
		width: 60px;
		height: 60px;
		background: #090c0f;
		bottom: 0px;
		margin-bottom: -30px;
		left: 50%;
		-webkit-transform: translateX(-50%) rotate(45deg);
		transform: translateX(-50%) rotate(45deg);
	}

	#entrance {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		color: #FFF;
		font-size: 24px;
		margin-top: 100px;
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
		text-align: center;
		z-index: 5;
		-webkit-transition: all 0.8s ease 0.3s;
		transition: all 0.8s ease 0.3s;
		
		@media screen and (max-width: 1400px) {	
			padding-top: 70px;	
		}		
		
		@media screen and (max-width: 992px) {	
			width: 100%;
		}
		
		&.show {
			visibility: visible;
			opacity: 1;	
			margin-top: 50px;
				
			@media screen and (max-width: 1380px) {	
				margin-top: 0px;
			}			
		}
		
		#logo {
			display: inline-block;
			width: 220px;
			height: 104px;
			background: url('../img/logo-restaurant.svg');
			background-size: 220px 104px;
			
			&.small {
				width: 220px;
				height: 104px;
				background: url('../img/logo-restaurant.svg');
				background-size: 220px 104px;
					
				@media screen and (max-width: 400px) {	
					width: 200px;
					height: 94px;
					background-size: 200px 94px;			
				}	

				@media screen and (max-width: 320px) {	
					width: 160px;
					height: 75px;
					background-size: 160px 75px;			
				}					
			}
			
			@media screen and (max-width: 1400px) {
				margin-top: 70px;
				width: 220px;
				height: 104px;
				background: url('../img/logo-restaurant.svg');
				background-size: 220px 104px;			
			}
			
			@media screen and (max-width: 768px) {	
				margin-top: 0px;
				width: 240px;
				height: 113px;
				background-size: 240px 113px;
			}
			
			@media screen and (max-width: 400px) {	
				width: 200px;
				height: 94px;
				background-size: 200px 94px;			
			}	

			@media screen and (max-width: 320px) {	
				width: 160px;
				height: 75px;
				background-size: 160px 75px;			
			}	
			
		}
		
		.hr {
			display: inline-block;
			clear: both;
			width: 100%;
			height: 1px;
			background: #FFF;
			opacity: 0.7;
		}
		
		p {	
			width: 100%;
			padding: 30px 0px;
			padding-bottom: 15px;
			margin: 0px;
			font-weight: 300;
			letter-spacing: 2px;
			
			@media screen and (max-width: 768px) {
				font-size: 18px;
			}
			
			@media screen and (max-width: 400px) {			
				padding: 20px 10px;
			}		
			
			&:first-of-type {
				@media screen and (max-width: 320px) {			
					display: none;
				}
			}			

		}
		
		h1 {
			font-size: 88px;
			padding: 0px;
			margin: 0px;
			
			&:after {
				display: none;
			}
			
			@media screen and (max-width: 1400px) {	
				font-size: 42px;	
			}
			
			@media screen and (max-width: 768px) {		
				font-size: 28px;	
				padding: 0px 15px;
			}				
			
		}
		
		.special {
			display: inline-block;
			position: relative;
			line-height: 32px;
			
			&:after {
				content: "";
				position: absolute; 
				width: 8px;
				height: 6px;
				background: url('../img/apostrof-white.png');
				top: 4px;
				left: 2px;
			}
		}					
		
		.button {
			margin: 0px 10px;
			min-width: 200px;
			color: #FFF;
			border: 2px solid #FFF;
					
			@media screen and (max-width: 768px) {
				font-size: 16px;
				min-width: 160px;				
			}
			
			@media screen and (max-width: 400px) {
				font-size: 15px;
				min-width: 100px;
				margin: 0px 5px;
			}			
			
			@media screen and (max-width: 320px) {			
				padding: 15px 10px;
				min-width: 80px;
			}			
			
			&:hover {
				background: #FFF;
				color: #010101
			}
					
			&:after {
				border-color: #FFF;
				width: -webkit-calc(100% + 4px);
				width: calc(100% + 4px);
				height: -webkit-calc(100% + 4px);
				height: calc(100% + 4px);				
			}			
		}
		
		a {
			color: #FFF;
		}
	}	
}

section {

	p {
		font-size: 20px;
		font-weight: 300;
		color: #272626;
		letter-spacing: 2px;
		line-height: 32px;
		padding: 0px 0px 20px 0px;
		margin: 0px;
		
		@media screen and (max-width: 1300px) {
			font-size: 18px;
			line-height: 30px;	
		}			
	}
	
	.content {
		a {
			color: #272626;
		}
	}
	
	&.collage {
		width: 100%;
		background: url('../img/bg_line.gif') center;
		padding: 0px 0px 70px 0px;
		margin: 0px;
	}		

	&.lead {
		width: 100%;
		background: url('../img/bg_line.gif') center;
		padding: 100px 0px 70px 0px;
		margin: 0px;
		
		@media screen and (max-width: 768px) {	
			padding: 50px 0px 20px 0px;	
		}		
		
		h1 {
			position: relative;
			font-size: 52px;
			color: #010101;
			
			@media screen and (max-width: 768px) {				
				font-size: 28px;
			}
		}
		
		a {
			color: #272626;
		}			
		
		.button {
			margin-top: 10px;
		}
		
		.img {
			position: relative;
			padding: 30px;
			
			.mark {
				position: absolute;
				width: 194px;
				height: 211px;
				background: url('../img/mark.png');
				bottom: -100px;
				left: -100px;
			}
			
			img {
				margin-left: -15px;
				max-width: 100%;
				height: auto;	
				
				@media screen and (max-width: 768px) {	
					margin-left: 0px;				
				}
			}
		}
		
		.content {
			div {
				padding: 30px;	
			}
		}
		
		&.intro {
			text-align: center;
			padding-top: 100px;
			
			p {
				position: relative;
				
				&:last-child {
					&:after {
						content: "";
						position: absolute;
						bottom: -20px;
						width: 100px;
						height: 2px;
						background: #000;
						left: 50%;
						-webkit-transform: translateX(-50%);
						transform: translateX(-50%);
					}
				}
			}
		}
	}	
	
	&.tail {
		width: 100%;
		background: url('../img/bg_line.gif') center;
		padding: 100px 0px 70px 0px;
		text-align: center;
						
		p {
			position: relative;
		}	

		a {
			color: #272626;
		}		
		
		.divider {
			display: inline-block;
			position: relative;
			width: 100px;
			height: 2px;
			margin: 20px 0px 40px 0px;
			background: #000;
		}	
	}		
	
	&.rooms {
		width: 100%;
		background: url('../img/bg_line.gif') center;
		padding: 100px 0px 70px 0px;

		.image {
			padding: 0px;

			img {
				max-width: 100%;
				height: auto;
			}
		}

		.content {
			padding: 0px;

			.block {
				position: relative;
				width: -webkit-calc(100% - 50px);
				width: calc(100% - 50px);
				background: #eaeaea;
				margin: -60px -60px 0 0;
				padding: 25px;
				float: right;
				z-index: 2;
 

				@media screen and (max-width: 1080px) {
					margin: 0 0 -60px 0;
					width: 100%; 
				}

				@media screen and (max-width: 768px) {
					width: -webkit-calc(100% - 40px);
					width: calc(100% - 40px);
					right: 20px;
					padding: 15px;
				}

				.inner {
					padding: 70px;
					border: 1px solid #e0e0e0;

					@media screen and (max-width: 992px) {
						padding: 40px;
					}

					@media screen and (max-width: 768px) {
						padding: 20px;
					}

					h1 {
						font-size: 50px;

						@media screen and (max-width: 768px) {
							font-size: 28px;
						}
					}

					p {
						padding-bottom: 30px;
					}

				}

			}
		}
	}

    &.events {
        width: 100%;
        background: url('../img/bg_line.gif') center;
        padding: 100px 0px 70px 0px;
    }
	
	&.kitchen {
		position: relative;
		width: 100%;
		height: 800px;
		background: url('../img/barbrasseriejoris.jpg') center;
		background-size: cover;
		
		@media screen and (max-width: 768px) {				
			width: 100%;
			background: url('../img/barbrasseriejoris-mobile.jpg') center;
			background-size: cover;
			height: 400px;
		}		
		
		span.nook {
			position: absolute;
			width: 60px;
			height: 60px;			
			top: 0px;
			left: 50%;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			margin-left: -30px;
			margin-top: -30px;
			background: #FFF;
			
			&:before {
				content: "";
				position: absolute;
				width: 1px;
				height: 138%;
				background: #e5e5e5;
				-webkit-transform: rotate(-45deg) translateX(-50%);
				transform: rotate(-45deg) translateX(-50%);
				top: -12px;
				left: 50%;
			}			
		}
		
		.container {
			position: relative;
			height: 100%;
			
			.content {
				position: absolute;
				background: #FFF;
				width: 100%;
				padding: 25px;
				margin: 0px;			
				bottom: -100px;
				left: 0px;
				text-align: center;

                .block {

                    .inner {
                        padding: 20px;
                        border: 1px solid #e0e0e0;
                    }
                }
				
				@media screen and (max-width: 1280px) {	
					width: -webkit-calc(100% - 70px);
					width: calc(100% - 70px);
					left: 35px;
				}
				
				@media screen and (max-width: 1280px) {	
					width: -webkit-calc(100% - 70px);
					width: calc(100% - 70px);
					left: 35px;
				}				
				
				@media screen and (max-width: 768px) {	
					width: -webkit-calc(100% - 40px);
					width: calc(100% - 40px);
					left: 20px;
					bottom: -150px;
					padding: 20px;
				}								
				
				h2 {
					position: relative;
					display: inline-block;
					text-transform: lowercase;
					font-size: 36px;
					padding-bottom: 20px;
					margin-bottom: 20px;

					&:after {
						content: "";
						position: absolute;
						width: 100px;
						height: 2px;
						background: #010101;
						bottom: 0px;
						left: -30px;
					}
				}
			}			
		}
	}
	
	&.people {
		background: url('../img/bg_line2.gif') #eaeaea center;
		padding: 270px 0px 70px 0px;

		.image {
			padding: 0px;
			
			img {
				max-width: 100%;
				height: auto;			
			}
		}	
		
		.content {
			padding: 0px;
			
			.block {
				position: relative;
				width: -webkit-calc(100% - 50px);
				width: calc(100% - 50px);			
				background: #FFF;
				margin: -60px 0px 0px -60px;
				padding: 25px;

				@media screen and (max-width: 1080px) {				
					margin-left: 0px;
					width: 100%;
				}
				
				@media screen and (max-width: 768px) {	
					width: -webkit-calc(100% - 40px);
					width: calc(100% - 40px);		
					left: 20px;						
					padding: 15px;
				}
				
				.inner {
					padding: 70px;	
					border: 1px solid #e0e0e0;	

					@media screen and (max-width: 992px) {	
						padding: 40px;
					}										
					
					@media screen and (max-width: 768px) {	
						padding: 20px;
					}					
					
					h1 {
						font-size: 50px;
							
						@media screen and (max-width: 768px) {	
							font-size: 28px;
						}						
					}	
					
					p {
						padding-bottom: 30px;
					}
					
				}
	
			}
		}
	}
	
	&.article {
		background: url('../img/bg_line2.gif') #eaeaea center;
		padding: 270px 0px 70px 0px;
		
		@media screen and (max-width: 768px) {	
			padding-top: 70px;
		}		

		.image {
			padding: 0px;
			
			img {
				max-width: 100%;
				height: auto;			
			}			
		}	
		
		.content {
			padding: 0px;

			&.privacy-content {
				.block {
					font-size: 17px;

					p {
						font-size: 17px;
						line-height: 26px;
					}

					ul {
						margin-bottom: 30px;

						li {
							font-size: 17px;
							font-weight: 300;
							color: #272626;
							letter-spacing: 2px;
						}
					}
				}
			}
			
			.block {
				position: relative;
				width: -webkit-calc(100% - 50px);
				width: calc(100% - 50px);			
				background: #FFF;
				margin: -60px 0px 0px -60px;
				padding: 25px;

				@media screen and (max-width: 1080px) {				
					margin-left: 0px;
					width: 100%;
				}
				
				@media screen and (max-width: 768px) {	
					width: -webkit-calc(100% - 40px);
					width: calc(100% - 40px);		
					left: 20px;			
					padding: 15px;
				}
								
				.inner {
					padding: 70px;	
					border: 1px solid #e0e0e0;	
					
					@media screen and (max-width: 768px) {	
						padding: 20px;
					}

					h1 {
						font-size: 50px;
							
						@media screen and (max-width: 768px) {	
							font-size: 28px;
						}						
					}	
					
					p {
						padding-bottom: 30px;
					}
							
					a {
						word-wrap: break-word;					
					}			
				}
	
			}
		}
	}	
	
	&#subscribe {
		background: #1f1e1e;
		padding: 50px 0px;
		
		p {
			color: #FFF;
		}	
		
		form {
			.inputfield {
				position: relative;
				width: 100%;
				height: 70px;
				background: #FFF;
				z-index: 2;
				
				&:after {
					content: "";
					position: absolute;
					width: 100%;
					height: 70px;
					border: 1px solid #FFF;
					top: 5px;
					left: 5px;
					z-index: -1;
				}
				
				input {
					float: left;
					width: -webkit-calc(100% - 70px);
					width: calc(100% - 70px);
					height: 70px;
					border: 0px;
					padding: 10px 25px;
					font-size: 18px;
					-webkit-transition: all 0.3s ease;
					transition: all 0.3s ease;					
					
					&.errorField {
						background: #ca9eaa;
						color: #FFF;
					}
				}
				
				button {
					float: left;
					width: 70px;
					height: 70px;
					background: url('../img/submit.svg') center no-repeat;
					background-size: 50px 50px;
					border: 0px;
					cursor: pointer;
				}
			}
		}
	}
}

footer {
	width: 100%;
	min-height: 780px;
	background: url('../img/footer.jpg') center;
	background-size: cover;
	
	@media screen and (max-width: 992px) {	
		background: #2a2a2a;
	}

	.logo-restaurant {
		display: block;
		clear: both;
		width: 160px;
		height: 110px;
		margin-bottom: 20px;
		background: url('../img/logo-restaurant-dark.svg') 0px 0px;
		background-size: 160px 110px;
	}

	p {
		font-weight: 300;
		letter-spacing: 2px;
	}

	address {
		font-style: normal;
		font-weight: 300;
		letter-spacing: 2px;

		ul {
			padding: 0;
			margin: 0;
			list-style: none;

			li {
				position: relative;
				padding-left: 24px;

				&:nth-child(1) {
					&:before {
						content: "";
						position: absolute;
						width: 16px;
						height: 16px;
						background: url('../img/icon-phone.png');
						background-size: 16px 16px;
						left: 0;
						top: 4px;
					}
				}

				&:nth-child(2) {
					&:before {
						content: "";
						position: absolute;
						width: 16px;
						height: 16px;
						background: url('../img/icon-email.png');
						background-size: 16px 16px;
						left: 0;
						top: 4px;
					}
				}

				&:nth-child(3) {
					margin-top: 20px;

					&:before {
						content: "";
						position: absolute;
						width: 16px;
						height: 16px;
						background: url('../img/icon-facebook.svg');
						background-size: 16px 16px;
						left: 0;
						top: 3px;
					}
				}

				a {
					text-decoration: none;
				}
			}
		}
	}
	
	.footer {
		padding-left: 100px;
		padding-right: 100px;
		
		@media screen and (max-width: 1260px) {	
			padding-left: 60px;
			padding-right: 60px;		
		}
		
		@media screen and (max-width: 768px) {	
			padding-left: 30px;
			padding-right: 30px;		
		}		
		
		.col {
			padding: 0px;
			
			.block {
				position: relative;
				width: 100%;
				padding: 70px;
				font-size: 18px;
				
				@media screen and (max-width: 992px) {	
					padding: 50px;
					min-height: 550px;
				}
				
				@media screen and (max-width: 768px) {	
					padding: 20px;
					min-height: 450px;
				}						
				
				h2 {
					position: relative;
					text-transform: lowercase;
					letter-spacing: 2px;
					color: #FFF;
					padding: 0px 0px 30px 0px;	
					margin: 0px 0px 30px 0px;

					@media screen and (max-width: 1300px) {	
						font-size: 20px;
					}
					
					&:after {
						content: "";
						position: absolute;
						width: 60px;
						height: 2px;
						background: #FFF;
						bottom: 0px;
						left: -20px;
							
						@media screen and (max-width: 768px) {	
							left: -10px;
						}						
					}					
				}					
				
				p {
					padding-bottom: 25px;
				}			
				
				&.block-1 {
					float: right;
					background: #FFF;
					margin-top: 150px;
					color: #272626;
					
					@media screen and (max-width: 992px) {	
						margin-top: 80px;
					}			

					@media screen and (max-width: 768px) {	
						float: left;
						margin-top: 30px;
					}	

					a {
						color: #272626;
					}					
					
					.logo {
						width: 175px;
						height: 89px;
						margin-bottom: 30px;
					}

                    .instagram {
                        position: absolute;
                        top: 0px;
                        right: 50px;

                        svg {
                            position: absolute;
                            top: 30px;
                            right: 30px;
                            width: 42px;
                            height: 42px;

                            * {
                                fill: #a4bab8;
                            }
                        }
                    }

					.facebook {
						position: absolute;
						top: 0px;
						right: 0px;
								
						svg {
							position: absolute;
							top: 30px;
							right: 30px;
							width: 42px;
							height: 42px;
							
							* {
								fill: #a4bab8;
							}
						}						
					}
				}
				
				&.block-2 {
					background: #1f1e1e;
					margin-top: 80px;
					color: #FFF;
					
					p {
						line-height: 28px;
					}
	
					em {
						white-space: nowrap;
						font-style: normal;	
					}
	
					@media screen and (max-width: 768px) {	
						float: left;
						margin-top: 30px;
					}						
				}
				
				&.block-3 {			
					margin-top: 150px;
					color: #FFF;
					
					@media screen and (max-width: 992px) {	
						float: left;
						margin-top: 60px;
					}
					
					@media screen and (max-width: 768px) {	
						margin-top: 30px;
					}	

					.button {
						border-color: #FFF;
						color: #FFF;
						
						&:after {
							border-color: #FFF;
						}
						
						&:hover {
							background: #FFF;
							color: #272626;
						}
					}
				}
								
			}		
			 
		}
		
		.copyright {
			text-align: center;
			padding-top: 15px;
			
			p {
				color: #FFF;
				
				a {
					color: #FFF;
				}
			}
		}
	}
} 

#overlay-container {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	background: rgba(0,0,0,0.4);
	z-index: 99999;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;	
	opacity: 0;
	visibility: hidden;
	
	&.show {
		opacity: 1;
		visibility: visible;	
	
		#overlay {
			-webkit-transform: translateX(-50%) translateY(-50%) scale(1);
			transform: translateX(-50%) translateY(-50%) scale(1);			
		}
	}
}

#overlay {
	position: fixed;
	width: -webkit-calc(100% - 100px);	
	width: calc(100% - 100px);
	max-width: 1400px;
	height: -webkit-calc(100% - 100px);	
	height: calc(100% - 100px);		
	background: #FFF;
	z-index: 9999;
	left: 50%;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%) scale(0);
	transform: translateX(-50%) translateY(-50%) scale(0);
	-webkit-transition: -webkit-transform 0.3s ease;
	transition: transform 0.3s ease;
	
	@media screen and (max-width: 1370px) {	
		width: -webkit-calc(100% - 40px);	
		width: calc(100% - 40px);
		height: -webkit-calc(100% - 40px);	
		height: calc(100% - 40px);			
	}
	
	@media screen and (max-width: 768px) {	
		width: 100%;
		height: 100%;
	}
			
	.close {
		position: absolute;
		width: 74px;
		height: 74px;
		background: #FFF;
		top: 0px;
		right: 0px;
		opacity: 1;
		z-index: 5;
		
		i {
			position: absolute;
			width: 64px;
			height: 64px;
			border: 1px solid #eaeaea;
			background: url('../img/close.svg') center no-repeat;
			background-size: 32px 32px;
			left: 50%;
			top: 50%;
			-webkit-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);
		}
	}		
	
	
	&.small {
		width: 500px;
		height: 250px;
		padding: 10px;
		font-size: 18px;
		
		@media screen and (max-width: 640px) {	
			width: 100%;
			height: auto;
		}			
			
		.close {
			position: absolute;
			width: 32px;
			height: 32px;
			background: url('../img/close.svg') center;
			background-size: 32px 32px;
			top: 20px;
			right: 20px;
		}		
	}
	
	.inner {
		float: left;
		width: 100%;
		height: 100%;
		border: 1px solid #DDD;
		padding: 30px;
		
	}
	
	.reservation-wrapper {
		float: left;
		width: 100%;
		height: 100%;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;

		.reservation-content-left {		
			float: left;
			width: -webkit-calc(100% - 400px);
			width: calc(100% - 400px);
			display: flex;
			background: #FFF;
			
			@media screen and (max-width: 1100px) {	
				width: -webkit-calc(100% - 280px);
				width: calc(100% - 280px);
			}
			 
			@media screen and (max-width: 992px) {	
				width: 100%;	
			}
			
			p {
				float: left;
				width: -webkit-calc(100% - 400px);
				width: calc(100% - 400px);
				padding-right: 30px; 	
				
				@media screen and (max-width: 1100px) {	
					font-size: 18px;
				}				
			}
		}

		.reservation-content-right {
			float: left;
			width: 400px;
			background: #eaeaea;	
			
			@media screen and (max-width: 1100px) {			
				width: 280px;
			}

			@media screen and (max-width: 992px) {	
				display: none;	
			}			
			
			h2 {
				position: relative;
				font-size: 36px;
				text-transform: uppercase;
				padding: 0px 0px 30px 0px;	
				margin: 0px 0px 30px 0px;
				
				@media screen and (max-width: 1400px) {						
					padding-right: 70px;				
				}
				
				@media screen and (max-width: 1100px) {	
					font-size: 24px;
				}	
				
				&:after {
					content: "";
					position: absolute;
					width: 100px;
					height: 2px;
					background: #010101;
					bottom: 0px;
					left: -30px;
					
					@media screen and (max-width: 1370px) {
						left: -20px;
					}						
				}	
			}	

			p {
				font-size: 18px;
				padding: 0px 0px 20px 0px;
				margin: 0px;
				
				@media screen and (max-width: 1100px) {
					font-size: 16px;
					padding: 0px 20px 20px 0px;
				}
			}				
		}	
	} 
			
	.wrap {
		float: left;
		padding: 50px;
		height: 100%;
		overflow: auto;
			
		@media screen and (max-width: 1370px) {
			padding: 25px;			
		}
			
		@media screen and (max-width: 768px) {
			padding: 0px;
		}
			
		h1 {
			font-size: 48px;
			position: relative;
			text-transform: uppercase;
			padding: 0px 0px 40px 0px;	
			margin: 0px 0px 40px 0px;				
				
			@media screen and (max-width: 1370px) {
				font-size: 36px;
				padding: 0px 0px 25px 0px;	
				margin: 0px 0px 25px 0px;					
			}
					
			@media screen and (max-width: 768px) {
				display: none;
			}				
				
			&:after {
				content: "";
				position: absolute;
				width: 100px;
				height: 2px;
				background: #010101;
				bottom: 0px;
				left: -30px;
				
				@media screen and (max-width: 1370px) {
					left: -20px;
				}
			}	
			
		}	

		p {
			font-size: 20px;
			padding: 0px 0px 20px 0px;
			margin: 0px;	
			letter-spacing: 2px;

			@media screen and (max-width: 768px) {
				display: none;
			}								
		}
		
		iframe {
			float: left;
			width: 400px;
			height: 450px;
		}
			
	}
	 
}

.responsive-image {
	img {
		max-width: 100%;
		height: auto;
	}
}

@media screen and (max-width: 1200px) {
	.container {
		width: 100% !important;
	}
}

@media screen and (max-width: 992px) {
	.hide-sm {
		display: none !important;
	}
}

@media screen and (max-width: 768px) {
	.hide-xs {
		display: none !important;
	}
}


@import "team";
@import "menu";
@import "rooms";
@import "contact";